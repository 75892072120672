<template>
  <div>
    <div id="main">
      <div class="search-main">
        <!-- 搜索框 -->
        <div class="search-header js-search-header">
          <div class="search-form">
            <input
              type="text"
              class="search-form-ipt js-search-ipt l"
              placeholder="请输入想搜索的内容"
              v-model="searchValue"
            />
            <button @click="search" class="search-form-btn js-search-btn r">
              搜索
            </button>
          </div>
        </div>
        <div class="search-body">
          <div class="search-content">
            <div class="search-classify">
              <div class="all_content">
                <a href="#/search/?words=%E5%89%8D%E7%AB%AF" class="active">
                  全站课程
                  <span class="all_course_total" data-total="470">（470）</span>
                </a>
              </div>
            </div>
            <div class="search-course-list">
              <div
                class="
                  search-item
                  js-search-item
                  clearfix
                  search-item-recommend
                "
                v-for="(v, i) in data"
                :key="i"
                style="display: block"
              >
                <a
                  :href="v.href"
                  target="_blank"
                  class="js-zhuge-allResult item-img l js-recommend-statistics"
                  data-rid="7"
                >
                  <img :src="v.image" />
                  <p v-if="v.is" class="type">免费课</p>
                </a>
                <div class="item-detail l">
                  <a :href="v.href" target="_blank"
                    class="
                      js-zhuge-allResult js-item-title
                      item-title
                      js-recommend-statistics
                    "
                    >{{ v.title }}
                  </a>
                  <p class="item-desc">{{ v.content }}</p>
                  <div class="item-classify">
                    <span>总时长{{v.date}}小时</span>
                  </div>
                </div>
              </div>
            </div>
            <div id="search-page" class="page">
              <span class="disabled_page">首页</span>
              <span class="disabled_page">上一页</span>
              <a
                href="javascript:;"
                v-for="i in pageSize"
                :key="i"
                class="text-page-tag js-page-item"
                :class="{ active: active == i }"
                >{{ i }}</a
              >
              <a href="javascript:;" class="js-page-next">下一页</a>
              <a href="javascript:;" class="js-page-last">尾页</a>
            </div>
          </div>
          <div class="search-recommend r">
            <div class="hot-search search-labels">
              <div class="title">热搜关键词</div>
              <div class="labels-con js-zhuge-searchHot">
                <a
                  :href="`/search?searchName=${V}`"
                  v-for="(V, I) in fjc.host"
                  :key="I"
                  class="word label"
                  >{{ V }}</a
                >
              </div>
            </div>
            <div class="relate-search search-labels">
              <div class="title">相关关键词</div>
              <div class="labels-con">
                <a
                  :href="`/search?searchName=${V}`"
                  v-for="(V, I) in fjc.relevant"
                  :key="I"
                  class="word label"
                  >{{ V }}</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../ulti/ajax'
export default {
  data: () => {
    return {
      searchValue: "搜索内容",
      fjc: {},
      active: 1,
      pageSize: 10,
      pageNum: 1,
      total: 3,
      data:[],
    };
  },
  methods: {
    search() {
      window.location.href = "/search?searchName=" + this.searchValue;
    },
  },
 async created() {
    this.searchValue = this.$route.query.searchName || "";
    if(this.searchValue){
       this.data= (await api.search(this.searchValue)).data;
       this.fjc= (await api.search(this.searchValue)).host;
       console
    }
   document.title='p8课堂';
  },
};
</script>

<style>
@import url("../assets/css/search.css");
</style>
